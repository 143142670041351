<script setup lang="ts">
import { useI18n } from "vue-i18n";
const { t } = useI18n();
</script>
<template>
  <div class="mt-6">
    <div
      class="p-4 border border-custom-red rounded-2xl text-custom-red bg-custom-red bg-opacity-5"
    >
      <div class="flex">
        <svg class="svg-icon svg-attention mr-1.5 mt-0.5">
          <use xlink:href="#attention" width="100%" height="100%"></use>
        </svg>
        <div>
          <p v-if="header" class="font-bold text-lg">{{ header }}</p>
          <p v-else class="font-bold text-lg">
            <slot name="header">{{ t("payment_error") }}</slot>
          </p>
          <p v-if="message" class="text-custom-text">{{ message }}</p>
          <p v-else class="text-custom-text">
            <slot />
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
export default {
  name: "checkout-error",
  props: {
    message: {
      type: String,
      required: false,
    },
    header: {
      type: String,
      required: false,
    },
  },
};
</script>
<i18n lang="yaml">
en:
  payment_error: "Payment error"
nb:
  payment_error: "Betalingsfeil"
es:
  payment_error: "Error de pago"
uk:
  payment_error: "Помилка оплати"
pl:
  payment_error: "Błąd płatności"
</i18n>
