<script lang="ts" setup>
import { onMounted, ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import { useCoupon } from "@/scripts/application/composables";
import { CheckoutError } from "@/scripts/application/components";
const { t } = useI18n();
const enterDiscount = ref(false);
// const props = defineProps<{
//   coupon: ICoupon | null;
// }>();
const { coupon, checkCoupon, error } = useCoupon();

const couponCode = ref<string>();
watch(
  () => coupon.value,
  (newCoupon) => {
    if (newCoupon) couponCode.value = newCoupon;
  }
);
onMounted(() => {
  couponCode.value = coupon.value;
});
async function applyCoupon() {
  try {
    await checkCoupon(couponCode.value);
    enterDiscount.value = false;
  } catch (e) {
    return Promise.reject(e);
  }
}
</script>
<template>
  <div class="pt-7">
    <div v-if="enterDiscount">
      <div class="flex flex-col justify-center items-center">
        <div class="relative w-96">
          <input
            v-model="couponCode"
            class="block uppercase placeholder:normal-case py-2 px-3 border border-gray-300 bg-gray-200 bg-opacity-70 rounded-md placeholder-gray-500 focus:border-gray-500 transition outline-none w-full shadow-sm min-h-12 focus:bg-white h-14 pr-40"
            name="discount"
            placeholder="Code"
            type="text"
          />
          <button
            type="button"
            @click.prevent="applyCoupon"
            class="transition inline-flex items-center px-3 py-2 md:px-6 justify-center border font-medium rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 shadow-sm text-base min-h-11 absolute top-1 right-1 w-36 h-12 bg-primary text-white hover:bg-primary-700"
          >
            <span class="leading-none">{{ t("apply_code") }}</span>
          </button>
        </div>
        <div class="relative w-96">
          <checkout-error v-if="error">
            <template #header>{{ t("discount_error") }}</template>
            {{ error }}
          </checkout-error>
        </div>
      </div>
    </div>
    <div v-else class="text-center">
      <a
        href="#"
        class="text-primary cursor-pointer transition-colors hover:text-primary-700"
        @click.prevent="enterDiscount = true"
      >
        {{ t("enter_code") }}
      </a>
    </div>
    <p class="flex items-center justify-center pt-4 pb-1">
      <svg
        width="25"
        height="24"
        viewBox="0 0 25 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        class="text-primary"
      >
        <path
          d="M11.9882 21.7541C12.2822 21.9111 12.6512 21.9101 12.9452 21.7531C20.9571 17.4491 21.5261 9.0401 21.5191 6.6491C21.5182 6.2551 21.2842 5.9051 20.9232 5.7461L12.8732 2.1801C12.6142 2.0661 12.3192 2.0661 12.0602 2.1811L4.06615 5.7471C3.71115 5.9061 3.47915 6.2501 3.47415 6.6391C3.44015 9.0181 3.91915 17.4451 11.9882 21.7541ZM9.17415 10.2931L11.4672 12.5861L15.7602 8.2931L17.1742 9.7071L11.4672 15.4141L7.76015 11.7071L9.17415 10.2931Z"
          fill="currentColor"
        ></path>
      </svg>
      <span class="text-sm sm:text-normal font-bold text-custom-grey-2">
        {{ t("14_day_guarantee") }}
      </span>
    </p>
    <p class="text-sm sm:text-normal text-custom-grey-2 text-center">
      {{ t("refund_policy") }}
    </p>
  </div>
</template>
<i18n lang="yaml">
en:
  discount_error: Error applying coupon
  enter_code: Enter discount code
  apply_code: Apply code
  14_day_guarantee: 14 days satisfaction guarantee.
  refund_policy: Your booking is fully refundable 14 days after booking date.
nb:
  discount_error: Feilmelding
  enter_code: Skriv inn rabattkode
  apply_code: Sjekk
  14_day_guarantee: 14 dagers fornøydgaranti.
  refund_policy: Påmeldingen er refunderbar i 14 dager etter bestillingsdato.
es:
  discount_error: Error al aplicar el cupón
  enter_code: Ingrese el código de descuento
  apply_code: Aplicar
  14_day_guarantee: 14 días de garantía de satisfacción.
  refund_policy: Su reserva es totalmente reembolsable 14 días después de la fecha de reserva.
uk:
  discount_error: Помилка застосування купона
  enter_code: Введіть код знижки
  apply_code: Застосувати
  14_day_guarantee: 14 днів гарантії задоволення.
  refund_policy: Ваше бронювання повністю підлягає поверненню протягом 14 днів після дати бронювання.
pl:
  discount_error: Błąd podczas stosowania kuponu
  enter_code: Wprowadź kod rabatowy
  apply_code: Zastosuj
  14_day_guarantee: 14-dniowa gwarancja satysfakcji.
  refund_policy: Twoja rezerwacja jest w pełni zwrotna 14 dni po dacie rezerwacji.
</i18n>
