import { createI18n } from "vue-i18n";

export const numberFormats = {
  en: {
    currency: {
      style: "currency",
      currency: "NOK",
      notation: "standard",
      currencyDisplay: "code",
    },
    roundedCurrency: {
      style: "currency",
      currency: "NOK",
      notation: "standard",
      currencyDisplay: "code",
      maximumFractionDigits: 0,
    },

    decimal: {
      style: "decimal",
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    },
    percent: {
      style: "percent",
      useGrouping: false,
    },
  },
  nb: {
    currency: {
      style: "currency",
      currency: "NOK",
      useGrouping: true,
      currencyDisplay: "code",
    },
    roundedCurrency: {
      style: "currency",
      currency: "NOK",
      notation: "standard",
      currencyDisplay: "code",
      maximumFractionDigits: 0,
    },
    decimal: {
      style: "decimal",
      minimumSignificantDigits: 3,
      maximumSignificantDigits: 5,
    },
    percent: {
      style: "percent",
      useGrouping: false,
    },
  },
  uk: {
    currency: {
      style: "currency",
      currency: "NOK",
      useGrouping: true,
      currencyDisplay: "code",
    },
    roundedCurrency: {
      style: "currency",
      currency: "NOK",
      notation: "standard",
      currencyDisplay: "code",
      maximumFractionDigits: 0,
    },
    decimal: {
      style: "decimal",
      minimumSignificantDigits: 3,
      maximumSignificantDigits: 5,
    },
    percent: {
      style: "percent",
      useGrouping: false,
    },
  },
  es: {
    currency: {
      style: "currency",
      currency: "NOK",
      useGrouping: true,
      currencyDisplay: "code",
    },
    roundedCurrency: {
      style: "currency",
      currency: "NOK",
      notation: "standard",
      currencyDisplay: "code",
      maximumFractionDigits: 0,
    },
    decimal: {
      style: "decimal",
      minimumSignificantDigits: 3,
      maximumSignificantDigits: 5,
    },
    percent: {
      style: "percent",
      useGrouping: false,
    },
  },
  pl: {
    currency: {
      style: "currency",
      currency: "NOK",
      useGrouping: true,
      currencyDisplay: "code",
    },
    roundedCurrency: {
      style: "currency",
      currency: "NOK",
      notation: "standard",
      currencyDisplay: "code",
      maximumFractionDigits: 0,
    },
    decimal: {
      style: "decimal",
      minimumSignificantDigits: 3,
      maximumSignificantDigits: 5,
    },
    percent: {
      style: "percent",
      useGrouping: false,
    },
  },
};

const messages = {
  nb: {
    field_required: "feltet er påkrevd",
    field_invalid: "feltet er ugyldig",
    invalid_email: "E-postadressen er ugyldig",
    currency: {
      style: "currency",
      currency: "NOK",
      notation: "standard",
      currencyDisplay: "code",
    },
  },
  en: {
    field_required: "field is required",
    field_invalid: "field is invalid",
    invalid_email: "The email address is invalid",
  },
  es: {
    field_required: "campo es obligatorio",
    field_invalid: "campo es inválido",
    invalid_email: "La dirección de correo electrónico no es válida",
  },
  uk: {
    field_required: "поле є обов'язковим",
    field_invalid: "поле недійсне",
    invalid_email: "Недійсна адреса електронної пошти",
  },
  pl: {
    field_required: "pole jest wymagane",
    field_invalid: "pole jest nieprawidłowe",
    invalid_email: "Nieprawidłowy adres e-mail",
  },
};

export const i18n = createI18n({
  messages,
  numberFormats: numberFormats,
  legacy: false,
  locale: gon.locale == "no" ? "nb" : gon.locale,
  inheritLocale: true,
});
