import { ref } from "vue";
import type { IOrder } from "@/scripts/application/types";
import { getCourseOrder, updateCourseOrder } from "@/scripts/application/api";
import { useRoute } from "vue-router";

const order = ref<IOrder | null>(null);
const error = ref<string>();

export default function useOrder() {
  const route = useRoute();
  const { course_id, order_uid } = route.params;

  async function updateOrder(data: Partial<IOrder>) {
    const oldOrder = order.value;
    if (!oldOrder) return Promise.resolve();
    const newOrder = { ...oldOrder, ...data };
    order.value = newOrder as IOrder;
    try {
      order.value = await updateCourseOrder(
        course_id as string,
        order_uid as string,
        newOrder
      );
    } catch (err) {
      error.value = (err as Error).message;
      order.value = oldOrder;
      return Promise.reject(err);
    }
  }

  const fetchOrder = async () => {
    try {
      order.value = await getCourseOrder(
        course_id as string,
        order_uid as string
      );
    } catch (err) {
      error.value = (err as Error).message;
      return Promise.reject(err);
    }
  };

  return {
    order,
    fetchOrder,
    updateOrder,
    error,
  };
}
