<i18n lang="yaml">
en:
  do_it: Do it!
uk:
  do_it: Зроби це!
nb:
  do_it: Gjør det!
no:
  do_it: Gjør det!
es:
  do_it: ¡Hazlo!
pl:
  do_it: Zrób to!
</i18n>
<template>
  <div
    class="z-10 fixed bottom-0 left-0 right-0 px-4 py-2 shadow-custom-shadow bg-white sm:static sm:shadow-none sm:p-0"
  >
    <div class="sm:pt-5">
      <button
        :disabled="disabled"
        type="submit"
        class="transition inline-flex items-center px-3 py-2 md:px-6 justify-center border font-medium rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 shadow-sm text-base min-h-11 w-full sm:w-72 h-10 sm:h-14 bg-primary text-white disabled:bg-gray-400 hover:bg-primary-700"
      >
        <slot>
          <span class="leading-none">{{ t("do_it") }}</span>
          <svg class="svg-icon svg-chevron-right">
            <use xlink:href="#chevron-right" width="100%" height="100%"></use>
          </svg>
        </slot>
      </button>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { useI18n } from "vue-i18n";
const { t } = useI18n();
interface Props {
  disabled: boolean;
}
withDefaults(defineProps<Props>(), {
  disabled: false,
});
</script>
