<script setup lang="ts">
import { useI18n } from "vue-i18n";
import { computed, ref, watch } from "vue";
import { string } from "yup";
const emit = defineEmits<{
  (event: "update:modelValue", value: string): void;
}>();

interface Props {
  name: string;
  modelValue: any;
  rules?: object;
  placeholder?: string;
  type?: string;
  error?: string;
  optional?: boolean;
  required?: boolean;
  inputClass?: string;
  labelClass?: string;
}

const props = withDefaults(defineProps<Props>(), {
  type: "text",
  optional: false,
  required: false,
  inputClass: "",
  labelClass: "",
});
const { t } = useI18n();
const errorMessage = ref<string>();
const error = computed({
  get() {
    return errorMessage.value || props.error;
  },
  set(value) {
    errorMessage.value = value;
  },
});
const rule = string()
  .nullable()
  .when("$email", {
    is: true,
    then: (schema) =>
      schema.email(t("field_invalid")).required(t("field_required")),
  })
  .when("$required", {
    is: true,
    then: (schema) => schema.required(t("field_required")),
  });

const validate = (value: string) => {
  return rule
    .validate(value, {
      context: {
        required: props.required,
        email: props.type === "email",
        ...props.rules,
      },
    })
    .then(() => {
      errorMessage.value = undefined;
    })
    .catch((e) => {
      errorMessage.value = e.errors[0];
    });
};

watch(
  () => props.modelValue,
  (newV) => {
    validate(newV);
  }
);
</script>
<template>
  <label class="block w-full">
    <span
      class="flex items-end justify-between text-custom-text pb-1 text-sm font-semibold"
      :class="labelClass"
    >
      <slot></slot>
      <span
        v-if="!required && optional"
        class="text-custom-grey-2 font-normal"
        >{{ t("optional") }}</span
      >
    </span>
    <input
      :type="type"
      :name="name"
      :placeholder="placeholder"
      :aria-invalid="!!error"
      :aria-required="required"
      class="block py-2 px-3 border bg-gray-200 bg-opacity-70 rounded-md placeholder-gray-500 focus:border-gray-500 transition outline-none w-full shadow-sm min-h-12 focus:bg-white"
      :required="required"
      :class="{
        [inputClass]: true,
        'border-gray-300': !error,
        'border-custom-red': !!error,
      }"
      :value="modelValue"
      @input="emit('update:modelValue', $event.target.value)"
      @blur="validate($event.target.value)"
    />
    <span
      v-if="error"
      class="flex items-center text-custom-red text-xs font-medium mt-1"
    >
      <svg class="svg-icon svg-attention mr-1.5">
        <use xlink:href="#attention" width="100%" height="100%"></use>
      </svg>
      {{ error }}
    </span>
  </label>
</template>
<i18n lang="yaml">
en:
  optional: "Optional"
nb:
  optional: "Valgfritt"
es:
  optional: "Opcional"
uk:
  optional: "Необов'язково"
pl:
  optional: "Opcjonalnie"
</i18n>
