import { Application } from "@hotwired/stimulus";
import { registerControllers } from "stimulus-vite-helpers";
import "../scripts/application/checkout.ts";
import "../scripts/admin/index.ts";
import { createApp } from "vue";
import ConditionFieldset from "../scripts/components/ConditionFieldset.vue";
document.querySelectorAll<HTMLElement>(".condition-fieldset").forEach((el) => {
  createApp(ConditionFieldset, { ...el.dataset }).mount(el);
});
export const application = Application.start();

const controllers = import.meta.glob(
  "../scripts/admin_controllers/*_controller.ts",
  {
    eager: true,
  }
);
registerControllers(application, controllers);

// Example: Load Rails libraries in Vite.
//
// import ActiveStorage from '@rails/activestorage'
// ActiveStorage.start()
//
// // Import all channels.
// const channels = import.meta.glob('./**/*_channel.js', { eager: true })

// Example: Import a stylesheet in app/frontend/index.css
// import '~/index.css'
