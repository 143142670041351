import { ref } from "vue";
import type {
  IUserProfile,
  IUserProfileErrors,
} from "@/scripts/application/types";
import {
  getCurrentProfile,
  updateCurrentProfile,
} from "@/scripts/application/api";
import type { AxiosResponse } from "axios";
import axios from "axios";

const user_profile = ref<IUserProfile>();
export default function useUserProfile() {
  const user_profile_errors = ref<Partial<IUserProfileErrors>>({});
  const error = ref<string>();

  async function updateUserProfile() {
    if (!user_profile.value) return;
    try {
      user_profile.value = await updateCurrentProfile(user_profile.value);
      user_profile_errors.value = {};
      return user_profile.value;
    } catch (e) {
      if (axios.isAxiosError(e)) {
        const errResponse = e.response as AxiosResponse<
          Partial<IUserProfileErrors>
        >;
        user_profile_errors.value = errResponse.data;
        return Promise.reject(errResponse.data);
      } else throw e;
    }
  }

  async function fetchUserProfile() {
    try {
      user_profile.value = await getCurrentProfile();
      return user_profile.value;
    } catch (err) {
      error.value = (err as Error).message;
      return Promise.reject(err);
    }
  }

  return {
    user_profile,
    user_profile_errors,
    error,
    fetchUserProfile,
    updateUserProfile,
  };
}
