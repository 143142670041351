<script setup lang="ts">
import { useI18n } from "vue-i18n";
const { t } = useI18n();
</script>

<script lang="ts">
import type {
  StripeCardElement,
  StripeElements,
  StripeElementLocale,
} from "@stripe/stripe-js";
import { defineComponent, nextTick } from "vue";

const elementsOptions = {
  hidePostalCode: true,
  classes: {
    base: "block text-sm font-medium text-center py-4 px-3 border border-gray-300 bg-gray-200 bg-opacity-70 rounded-md placeholder-gray-500 focus:border-gray-500 transition outline-none w-full shadow-sm min-h-12 focus:bg-white",
  },
  style: {
    base: {
      backgroundColor: "transparent",
      fontSize: "16px",
    },
  },
};

export default defineComponent({
  name: "CheckoutStripeCard",
  props: {
    stripe: {
      type: Object,
    },
  },
  computed: {
    stripeElements(): StripeElements | null {
      console.log("computed stripeElements");
      if (!this.stripe) return null;
      return (
        this.stripe.elements({
          locale: gon.locale as StripeElementLocale,
        }) || null
      );
    },
    cardNumber(): StripeCardElement | undefined {
      console.log("computed cardNumber");
      return this.stripeElements?.create("card", elementsOptions);
    },
  },
  watch: {
    cardNumber() {
      console.log("watch cardNumber");
      nextTick(() => {
        this.mountCardNumber();
      });
    },
  },
  methods: {
    async getToken() {
      return this.stripe?.createToken(this.cardNumber as StripeCardElement);
    },
    mountCardNumber() {
      console.log("mountCardNumber");
      if (this.cardNumber) {
        this.cardNumber.mount(this.$refs.card as HTMLElement);
      }
    },
  },
  expose: ["getToken"],
  mounted() {
    this.mountCardNumber();
  },
  // beforeUnmount() {
  //   this.cardNumber?.unmount();
  // },
});
</script>

<template>
  <div
    ref="card"
    class="bg-gray-200 bg-opacity-70"
    :class="{ hidden: !stripe }"
  ></div>
  <div v-if="stripe === undefined" class="p-2">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      stroke-width="1.5"
      stroke="currentColor"
      class="w-6 h-6 animate animate-spin text-primary inline-block align-middle mr-1"
    >
      <path
        stroke-linecap="round"
        stroke-linejoin="round"
        d="M10.343 3.94c.09-.542.56-.94 1.11-.94h1.093c.55 0 1.02.398 1.11.94l.149.894c.07.424.384.764.78.93.398.164.855.142 1.205-.108l.737-.527a1.125 1.125 0 011.45.12l.773.774c.39.389.44 1.002.12 1.45l-.527.737c-.25.35-.272.806-.107 1.204.165.397.505.71.93.78l.893.15c.543.09.94.56.94 1.109v1.094c0 .55-.397 1.02-.94 1.11l-.893.149c-.425.07-.765.383-.93.78-.165.398-.143.854.107 1.204l.527.738c.32.447.269 1.06-.12 1.45l-.774.773a1.125 1.125 0 01-1.449.12l-.738-.527c-.35-.25-.806-.272-1.203-.107-.397.165-.71.505-.781.929l-.149.894c-.09.542-.56.94-1.11.94h-1.094c-.55 0-1.019-.398-1.11-.94l-.148-.894c-.071-.424-.384-.764-.781-.93-.398-.164-.854-.142-1.204.108l-.738.527c-.447.32-1.06.269-1.45-.12l-.773-.774a1.125 1.125 0 01-.12-1.45l.527-.737c.25-.35.273-.806.108-1.204-.165-.397-.505-.71-.93-.78l-.894-.15c-.542-.09-.94-.56-.94-1.109v-1.094c0-.55.398-1.02.94-1.11l.894-.149c.424-.07.765-.383.93-.78.165-.398.143-.854-.107-1.204l-.527-.738a1.125 1.125 0 01.12-1.45l.773-.773a1.125 1.125 0 011.45-.12l.737.527c.35.25.807.272 1.204.107.397-.165.71-.505.78-.929l.15-.894z"
      />
      <path
        stroke-linecap="round"
        stroke-linejoin="round"
        d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
      />
    </svg>
    <p class="align-middle inline-block">
      {{ t("loading_stripe") }}
    </p>
  </div>
  <div v-else-if="stripe === null" class="text-red-600 p-2">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      stroke-width="1.5"
      stroke="currentColor"
      class="w-6 h-6 inline-block align-middle mr-1"
    >
      <path
        stroke-linecap="round"
        stroke-linejoin="round"
        d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
      />
    </svg>
    <p class="align-middle inline-block">
      {{ t("stripe_load_error") }}
    </p>
  </div>
</template>
<i18n lang="yaml">
en:
  loading_stripe: Initializing payment system
  stripe_load_error: Error initializing payment system
nb:
  loading_stripe: Initialiserer betalingssystem
  stripe_load_error: Kunne ikke laste inn betalingssystemet
no:
  loading_stripe: Initialiserer betalingssystem
  stripe_load_error: Kunne ikke laste inn betalingssystemet
es:
  loading_stripe: Inicializando el sistema de pago
  stripe_load_error: Error cargando la libreria de pagos
uk:
  loading_stripe: Ініціалізація платіжної системи
  stripe_load_error: Помилка ініціалізації платіжної системи
pl:
  loading_stripe: Inicjalizacja systemu płatności
  stripe_load_error: Błąd inicjalizacji systemu płatności
</i18n>
