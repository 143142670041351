<i18n lang="yaml">
en:
  no_results: No results found in the public directory
  company_name: Company name
  billing_ref: PO / Reference
no:
  no_results: Fant ingen firma med dette navnet i offentlige registre
  company_name: Firmanavn
  billing_ref: Referansenummer på faktura
nb:
  no_results: Fant ingen firma med dette navnet i offentlige registre
  company_name: Firmanavn
  billing_ref: Referansenummer på faktura
uk:
  no_results: Не знайдено результатів у публічному каталозі
  company_name: Назва компанії
  billing_ref: ПО / посилання
es:
  no_results: No se encontraron resultados en el directorio público
  company_name: Nombre de la empresa
  billing_ref: PO / Referencia
pl:
  no_results: Nie znaleziono wyników w katalogu publicznym
  company_name: Nazwa firmy
  billing_ref: PO / Referencja
</i18n>
<template>
  <Combobox v-model="selected">
    <div class="relative">
      <label for="company-name" class="block w-full">
        <i18n-t
          tag="span"
          keypath="company_name"
          class="flex items-end justify-between text-custom-text pb-1 text-sm font-semibold"
        />
      </label>
      <div
        class="relative w-full cursor-default overflow-hidden rounded-lg bg-white text-left focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-gray-100 sm:text-sm"
      >
        <ComboboxInput
          id="company-name"
          class="py-2 pl-3 pr-10 leading-5 border border-gray-300 rounded-md cursor-pointer placeholder-gray-500 transition outline-none w-full min-h-12 text-custom-text bg-gray-200 focus:bg-gray-200 bg-opacity-70 focus:border-gray-300"
          :displayValue="(company: IBrregCompanyInfo) => (company?.name || '')"
          @change="query = $event.target.value"
        />
        <ComboboxButton
          class="absolute inset-y-0 right-0 flex items-center pr-2"
        >
          <ChevronUpDownIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
        </ComboboxButton>
      </div>
      <TransitionRoot
        leave="transition ease-in duration-100"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
        @after-leave="query = ''"
      >
        <ComboboxOptions
          class="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-gray-100 ring-opacity-5 focus:outline-none sm:text-sm"
        >
          <div
            v-if="companies.length === 0 && query !== ''"
            class="relative cursor-default select-none py-2 px-4 text-gray-700"
          >
            {{ t("no_results") }}
          </div>

          <ComboboxOption
            v-for="company in companies"
            as="template"
            :key="company.organizationId"
            :value="company"
            v-slot="{ selected, active }"
          >
            <li
              class="relative cursor-default select-none py-2 pl-10 pr-4"
              :class="{
                'bg-primary text-white': active,
                'text-gray-900': !active,
              }"
            >
              <span
                class="block truncate"
                :class="{ 'font-medium': selected, 'font-normal': !selected }"
              >
                {{ company.name }}
              </span>
              <span
                v-if="selected"
                class="absolute inset-y-0 left-0 flex items-center pl-3"
                :class="{ 'text-white': active, 'text-primary': !active }"
              >
                <CheckIcon class="h-5 w-5" aria-hidden="true" />
              </span>
            </li>
          </ComboboxOption>
        </ComboboxOptions>
      </TransitionRoot>
    </div>
  </Combobox>
</template>

<script lang="ts" setup>
import { ref, watch } from "vue";
import {
  Combobox,
  ComboboxInput,
  ComboboxButton,
  ComboboxOptions,
  ComboboxOption,
  TransitionRoot,
} from "@headlessui/vue";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/vue/24/solid";
import type { IBrregCompanyInfo } from "@/scripts/application/types";
import { getBrregCompanyInformation } from "@/scripts/application/api";
import { useI18n } from "vue-i18n";
const { t } = useI18n();

const companies = ref<IBrregCompanyInfo[]>([]);
const selected = ref<IBrregCompanyInfo | null>(null);
const query = ref("");

const emit = defineEmits<{
  (event: "company:selected", company: IBrregCompanyInfo): void;
}>();

watch(query, (val) => {
  if (val.length > 2) {
    getBrregCompanyInformation(val).then((data) => {
      companies.value = data;
    });
  }
});

watch(selected, (val) => {
  if (val) emit("company:selected", val);
});
</script>
