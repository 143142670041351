import axios, { AxiosError } from "axios";
import type { CancelTokenSource, AxiosResponse } from "axios";
import type {
  IAddress,
  IBrregCompanyInfo,
  ICard,
  ICountry, ICoupon,
  ICourse,
  IGroup,
  IOrder,
  IPaymentGateway,
  IUserProfile,
} from "@/scripts/application/types";
const csrfToken = function () {
  const meta = document.querySelector<HTMLMetaElement>("meta[name=csrf-token]");
  return meta?.content ?? false;
};
const api = axios.create({
  baseURL: "/api/falcon",
  headers: {
    "X-CSRF-Token": csrfToken(),
    "Content-Type": "application/json",
    Accept: "application/json",
  },
});

let getCurrentProfileCancelToken: CancelTokenSource;

export async function getCurrentProfile(): Promise<IUserProfile> {
  if (typeof getCurrentProfileCancelToken != typeof undefined) {
    getCurrentProfileCancelToken.cancel(
      "Operation canceled due to new request."
    );
  }
  getCurrentProfileCancelToken = axios.CancelToken.source();
  try {
    const {
      data: { user_profile },
    } = await api.get<{ user_profile: IUserProfile }>("/user_profile", {
      cancelToken: getCurrentProfileCancelToken.token,
    });

    return user_profile;
  } catch (error) {
    console.log(error);
    return Promise.reject(error);
  }
}

export async function getAddresses(): Promise<IAddress[]> {
  try {
    const {
      data: { addresses },
    } = await api.get<{ addresses: IAddress[] }>("/user_profile/addresses");

    return addresses;
  } catch (error) {
    console.log(error);
    return Promise.reject(error);
  }
}

export async function createAddress(
  addressData: Partial<IAddress>
): Promise<IAddress> {
  try {
    const {
      data: { address },
    } = await api.post<{ address: IAddress }>("/user_profile/addresses", {
      address: addressData,
    });

    return address;
  } catch (error) {
    console.log(error);
    return Promise.reject(error);
  }
}

export async function updateAddress(
  id: number | string,
  addressData: Partial<IAddress>
): Promise<IAddress> {
  try {
    const {
      data: { address },
    } = await api.put<{ address: IAddress }>(
      `/user_profile/addresses/${id}`,
      {
        address: addressData,
      }
    );

    return address;
  } catch (error) {
    console.log(error);
    return Promise.reject(error);
  }
}

// export function saveAddress(addressData: Partial<IAddress>): Promise<IAddress> {
//   if (addressData.id) {
//     return updateAddress(addressData);
//   } else {
//     return createAddress(addressData);
//   }
// }
//
export async function getCountriesList(): Promise<ICountry[]> {
  const {
    data: { countries },
  } = await api.get<{ countries: ICountry[] }>(
    "/user_profile/addresses/countries"
  );

  return countries;
}

export async function getCardsList(): Promise<ICard[]> {
  const {
    data: { cards },
  } = await api.get<{ cards: ICard[] }>("/user_profile/cards");

  return cards;
}

export async function createCard(token: string): Promise<ICard> {
  const {
    data: { card },
  } = await api.post<{ card: ICard }>("/user_profile/cards", {
    card: { token },
  });

  return card;
}

let updateCurrentProfileCancelToken: CancelTokenSource;

export async function updateCurrentProfile(
  profileData: IUserProfile
): Promise<IUserProfile> {
  if (typeof updateCurrentProfileCancelToken != typeof undefined) {
    updateCurrentProfileCancelToken.cancel(
      "Operation canceled due to new request."
    );
  }
  updateCurrentProfileCancelToken = axios.CancelToken.source();
  try {
    const {
      data: { user_profile },
    } = await api.patch<{ user_profile: IUserProfile }>(
      "/user_profile",
      {
        user_profile: profileData,
      },
      {
        cancelToken: updateCurrentProfileCancelToken.token,
      }
    );

    return user_profile;
  } catch (error) {
    console.log(error);
    return Promise.reject(error);
  }
}

export async function getCourse(id: number | string): Promise<ICourse> {
  const {
    data: { course },
  } = await api.get<{ course: ICourse }>(`/courses/${id}`);

  return course;
}

export async function getCourseOrder(
  course_id: number | string,
  order_uid: string
): Promise<IOrder> {
  const {
    data: { order },
  } = await api.get<{ order: IOrder }>(
    `/courses/${course_id}/orders/${order_uid}`
  );

  return order;
}

let updateCourseOrderCancelToken: CancelTokenSource;

export async function updateCourseOrder(
  course_id: number | string,
  order_uid: string,
  orderData: Partial<IOrder>
): Promise<IOrder> {
  if (typeof updateCourseOrderCancelToken != typeof undefined) {
    updateCourseOrderCancelToken.cancel(
      "Operation canceled due to new request."
    );
  }

  updateCourseOrderCancelToken = axios.CancelToken.source();
  try {
    const {
      data: { order: newOrder },
    } = await api.patch<{ order: IOrder }>(
      `/courses/${course_id}/orders/${order_uid}`,
      {
        order: orderData,
      },
      {
        cancelToken: updateCourseOrderCancelToken.token,
      }
    );

    return newOrder;
  } catch (error) {
    console.log(error);
    return Promise.reject(error);
  }
}
let updateTryCouponCancelToken: CancelTokenSource;


export async function tryCoupon(
    course_id: number | string,
    order_uid: string,
    code: string | null
): Promise<ICoupon> {
  if (typeof updateTryCouponCancelToken != typeof undefined) {
    updateTryCouponCancelToken.cancel(
        "Operation canceled due to new request."
    );
  }

  updateTryCouponCancelToken = axios.CancelToken.source();
  try {
    const {
      data: { coupon: newCoupon },
    } = await api.post<{ coupon: ICoupon }>(
        `/courses/${course_id}/orders/${order_uid}/try_coupon`,
        { code },
        {
          cancelToken: updateTryCouponCancelToken.token,
        },
    );

    return newCoupon;
  } catch (error) {
    console.log(error);
    return Promise.reject(error);
  }
}

export async function getCourseGroups(
  course_id: number | string
): Promise<IGroup[]> {
  const {
    data: { groups },
  } = await api.get<{ groups: IGroup[] }>(`/courses/${course_id}/groups`);

  return groups;
}

type tCheckoutOrderResponseOK = {
  type: "success";
  status: "ok";
  success_path: string;
  redirect_path: string;
  tracking: {
    email: string;
    total_price: number;
    order_id: string;
    item_name: string;
    item_price: number;
    item_id: string;
    item_quantity: 1;
    item_category: string;
    item_variety: string;
  };
};

type tCheckoutOrderResponseError =
  | stripe3DSResponse
  | stripePaymentFailed
  | generalFailureError;

type generalFailureError = {
  type: "general_failure";
  result: {
    message: string;
  };
  success_path: string;
  redirect_path: string;
};

type stripe3DSResponse = {
  type: "requires_action" | "requires_confirmation";
  result: {
    stripe_payment_intent: {
      client_secret: string;
    };
  };
  success_path: string;
  redirect_path: string;
};

type stripePaymentFailed = {
  type: "requires_payment_method";
  result: {
    stripe_payment_intent: {
      client_secret: string;
    };
  };
  success_path: string;
  redirect_path: string;
};

export async function submitOrder(
  course_id: number | string,
  order_uid: string,
  orderData: Partial<IOrder>
): Promise<tCheckoutOrderResponseOK | tCheckoutOrderResponseError> {
  try {
    const { data: result } = await api.post<tCheckoutOrderResponseOK>(
      `/courses/${course_id}/orders/${order_uid}/checkout`,
      {
        order: orderData,
      }
    );

    return result;
  } catch (e) {
    if (axios.isAxiosError(e)) {
      const errRespons =
        e.response as AxiosResponse<tCheckoutOrderResponseError>;
      return errRespons.data;
    } else throw e;
  }
}

export async function getPaymentGateways(
  course_id: number | string,
  order_uid: string
): Promise<IPaymentGateway[]> {
  const {
    data: { payment_gateways },
  } = await api.get<{ payment_gateways: IPaymentGateway[] }>(
    `/courses/${course_id}/orders/${order_uid}/payment_gateways`
  );

  return payment_gateways;
}

interface IBrregAddress {
  land: string;
  landkode: string;
  postnummer: string;
  poststed: string;
  adresse: string[];
  kommune: string;
  kommunenummer: string;
}

interface IBrregCompany {
  organisasjonsnummer: string;
  navn: string;
  organisasjonsform: {
    kode: string;
    beskrivelse: string;
    _links: {
      self: {
        href: string;
      };
    };
  };
  hjemmeside: string;
  registreringsdatoEnhetsregisteret: string;
  registrertIMvaregisteret: boolean;
  naeringskode1: {
    beskrivelse: string;
    kode: string;
  };
  antallAnsatte: number;
  forretningsadresse?: IBrregAddress;
  postadresse?: IBrregAddress;
  stiftelsesdato: string;
  institusjonellSektorkode: {
    kode: string;
    beskrivelse: string;
  };
  registrertIForetaksregisteret: boolean;
  registrertIFrivillighetsregisteret: boolean;
  registrertIStiftelsesregisteret: boolean;
  sisteInnsendteAarsregnskap: string;
  konkurs: boolean;
  underAvvikling: boolean;
  underTvangsavviklingEllerTvangsopplosning: boolean;
  maalform: string;
  _links: {
    self: {
      href: string;
    };
  };
}
interface IBrregResponse {
  _embedded?: {
    enheter: IBrregCompany[];
  };
  _links: {
    first: {
      href: string;
    };
    prev?: {
      href: string;
    };
    self: {
      href: string;
    };
    next?: {
      href: string;
    };
    last: {
      href: string;
    };
  };
  page: {
    size: number;
    totalElements: number;
    totalPages: number;
    number: number;
  };
}

let getBrregCompanyInformationCancelToken: CancelTokenSource;

export async function getBrregCompanyInformation(
  query: string
): Promise<IBrregCompanyInfo[]> {
  if (!query) return [];
  if (typeof getBrregCompanyInformationCancelToken != typeof undefined) {
    getBrregCompanyInformationCancelToken.cancel(
      "Operation canceled due to new request."
    );
  }

  getBrregCompanyInformationCancelToken = axios.CancelToken.source();
  try {
    const { data } = await axios.get<IBrregResponse>(
      "https://data.brreg.no/enhetsregisteret/api/enheter",
      {
        params: {
          navn: query,
        },
        cancelToken: getBrregCompanyInformationCancelToken.token,
      }
    );
    if (!data._embedded) return [];

    return data._embedded.enheter.map((company) => ({
      name: company.navn,
      organizationId: company.organisasjonsnummer,
      postalCode:
        company.forretningsadresse?.postnummer ||
        company.postadresse?.postnummer ||
        "",
      address:
        company.forretningsadresse?.adresse[0] ||
        company.postadresse?.adresse[0] ||
        "",
      city:
        company.forretningsadresse?.poststed ||
        company.postadresse?.poststed ||
        "",
    }));
  } catch (error) {
    console.log(error);
    return Promise.reject(error);
  }
}
