import { createApp } from "vue";
import { createRouter, createWebHistory, RouterView } from "vue-router";
import CheckoutPage from "./pages/CheckoutPage.vue";
import * as Sentry from "@sentry/vue";
import { i18n } from "@/scripts/application/i18n";

const container = document.getElementById("checkout");
const routes = [
  {
    path: "/courses/:course_id/orders/:order_uid/checkout",
    component: CheckoutPage,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});
const mountApp = (el: HTMLElement) => {
  const app = createApp(RouterView);
  if (import.meta.env.VITE_SENTRY_DSN) {
    Sentry.init({
      app,
      dsn: import.meta.env.VITE_SENTRY_DSN,
      // Set tracesSampleRate to 1.0 to capture 100%
      // of transactions for performance monitoring.
      // We recommend adjusting this value in production
      environment: import.meta.env.NODE_ENV,
      enabled: import.meta.env.NODE_ENV === "production",
      tracesSampleRate: 0.1,
    });
  }

  app.use(router);
  app.use(i18n);
  return app.mount(el);
};
if (container) mountApp(container);
