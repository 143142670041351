import { ref } from "vue";
import type { IPaymentGateway } from "@/scripts/application/types";
import { getPaymentGateways } from "@/scripts/application/api";
import { useRoute } from "vue-router";

const paymentGateways = ref<IPaymentGateway[]>([]);
const error = ref<string>();

export default function usePaymentGateways() {
  const route = useRoute();

  const fetchPaymentGateways = async () => {
    try {
      paymentGateways.value = await getPaymentGateways(
        route.params.course_id as string,
        route.params.order_uid as string
      );
    } catch (err) {
      error.value = (err as Error).message;
      return Promise.reject(err);
    }
  };

  return {
    paymentGateways,
    error,
    fetchPaymentGateways,
  };
}
