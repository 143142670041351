<script setup lang="ts">
import { ref } from "vue";
import { useI18n } from "vue-i18n";
import {
  CheckoutAction,
  InputWithLabel,
} from "@/scripts/application/components";
import { useUserProfile } from "@/scripts/application/composables";

const { t } = useI18n();
const {
  user_profile,
  user_profile_errors,
  updateUserProfile,
  error: profileError,
} = useUserProfile();
const loading = ref(false);

const emit = defineEmits<{
  (event: "loading", value: boolean): void;
  (event: "done"): void;
}>();

const updateProfile = () => {
  emit("loading", true);
  loading.value = true;
  updateUserProfile()
    .then(() => {
      if (!profileError.value) emit("done");
      emit("loading", false);
      loading.value = false;
    })
    .catch(() => {
      emit("loading", false);
      loading.value = false;
    });
};
</script>
<template>
  <div class="flex-1">
    <div class="pb-8">
      <h1 class="text-3xl sm:text-5xl font-extrabold text-custom-text">
        {{ t("booking") }}
      </h1>
    </div>
    <div class="pt-5">
      <h3 class="text-xl font-bold mb-4 text-custom-grey-1">
        {{ t("participant_information") }}
      </h3>
      <form @submit.prevent="updateProfile" v-if="user_profile">
        <div
          class="pt-4 lg:flex justify-between space-y-4 lg:space-y-0 lg:space-x-3"
        >
          <input-with-label
            required
            type="text"
            v-model="user_profile.first_name"
            :error="user_profile_errors.first_name?.[0]"
            name="first_name"
          >
            {{ t("first_name") }}
          </input-with-label>
          <input-with-label
            required
            v-model="user_profile.last_name"
            name="last_name"
            :error="user_profile_errors.last_name?.[0]"
          >
            {{ t("last_name") }}
          </input-with-label>
        </div>
        <div class="pt-4">
          <label class="block w-full">
            <span
              class="flex items-end justify-between text-custom-text pb-1 text-sm font-semibold"
            >
              {{ t("email") }}
            </span>
            <input
              type="email"
              v-model="user_profile.email"
              name="email"
              placeholder=""
              disabled
              class="block py-2 px-3 border border-gray-300 bg-gray-200 bg-opacity-70 rounded-md placeholder-gray-500 focus:border-gray-500 transition outline-none w-full shadow-sm min-h-12 focus:bg-white"
            />
          </label>
        </div>
        <div class="pt-4">
          <input-with-label
            type="text"
            required
            v-model="user_profile.telephone_number"
            name="telephone_number"
            :error="user_profile_errors.telephone_number?.[0]"
          >
            {{ t("mobile_number") }}
          </input-with-label>
        </div>
        <div
          class="z-10 fixed bottom-0 left-0 right-0 px-4 py-2 shadow-custom-shadow bg-white sm:static sm:shadow-none sm:p-0"
        >
          <div class="sm:pt-10">
            <checkout-action :disabled="loading">
              <span class="leading-none"> {{ t("next") }} </span>
              <svg class="svg-icon svg-chevron-right">
                <use
                  xlink:href="#chevron-right"
                  width="100%"
                  height="100%"
                ></use>
              </svg>
            </checkout-action>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>
<i18n lang="yaml">
en:
  booking: "Booking"
  back: "Back"
  first_name: "First name"
  last_name: "Last name"
  participant_information: Fill in the details of the course participant
  email: "Email"
  mobile_number: Mobile number
  next: "Next"
nb:
  booking: "Påmelding"
  back: "Tilbake"
  first_name: "Fornavn"
  last_name: "Etternavn"
  participant_information: Informasjon om kursdeltaker
  email: "E-post"
  mobile_number: Mobilnummer
  next: "Neste"
uk:
  booking: "Бронювання"
  back: "Назад"
  first_name: "Ім'я"
  last_name: "Прізвище"
  participant_information: Заповніть дані про учасника курсу
  email: "Електронна пошта"
  mobile_number: Мобільний номер
  next: "Далі"
es:
  booking: "Inscripción"
  back: "Volver"
  first_name: "Nombre"
  last_name: "Apellido"
  participant_information: Rellene los datos del participante del curso
  email: "Correo electrónico"
  mobile_number: Número de teléfono móvil
  next: "Siguiente"
pl:
  booking: "Rezerwacja"
  back: "Wstecz"
  first_name: "Imię"
  last_name: "Nazwisko"
  participant_information: Wypełnij dane uczestnika kursu
  email: "E-mail"
  mobile_number: Numer telefonu komórkowego
  next: "Dalej"
</i18n>
