import { ref } from "vue";
import type { ICourse } from "@/scripts/application/types";
import { getCourse } from "@/scripts/application/api";
import { useRoute } from "vue-router";

export default function useCourse() {
  const course = ref<ICourse>();
  const error = ref<string>();
  const route = useRoute();

  async function fetchCourse() {
    try {
      course.value = await getCourse(route.params.course_id as string);
    } catch (err) {
      error.value = (err as Error).message;
      return Promise.reject(err);
    }
  }

  return {
    course,
    error,
    fetchCourse,
  };
}
