import { ref } from "vue";
import type { ICountry } from "@/scripts/application/types";
import { getCountriesList } from "@/scripts/application/api";

const countries = ref<ICountry[]>();
export default function useCountries() {
  const error = ref<string>();

  async function fetchCountries() {
    countries.value = await getCountriesList();
  }

  return {
    countries,
    error,
    fetchCountries,
  };
}
