import type { IAddress } from "@/scripts/application/types";
import { ref, unref } from "vue";
import {
  createAddress,
  getAddresses,
  updateAddress,
} from "@/scripts/application/api";

const emptyAddress: IAddress = {
  id: null,
  company: null,
  contact_person: "",
  organization_id: null,
  state: null,
  address1: "",
  address2: null,
  city: "",
  country: "NO",
  postal_code: null,
  billing_email: null,
  billing_ref: null,
};

const addresses = ref<IAddress[]>();
const addressParams = ref<IAddress>({
  ...emptyAddress,
});

function resetAddressParams() {
  addressParams.value = {
    ...emptyAddress,
  };
}

export default function useAddresses() {
  const error = ref<string>();

  const fetchAddresses = async () => {
    try {
      addresses.value = await getAddresses();
    } catch (err) {
      error.value = (err as Error).message;
      return Promise.reject(err);
    }
  };

  const saveAddress = async () => {
    const addressData = unref(addressParams);
    if (addressData.id) {
      try {
        const newAddress = await updateAddress(addressData.id, addressData);
        if (addresses.value) {
          const index = addresses.value.findIndex(
            (address) => address.id === newAddress.id
          );
          if (index !== -1) {
            addresses.value[index] = newAddress;
          }
        }
        return newAddress;
      } catch (e) {
        error.value = (e as Error).message;
        return Promise.reject(e);
      }
    }
    try {
      const newAddress = await createAddress(addressData);
      if (addresses.value) {
        addresses.value.push(newAddress);
      }
      return newAddress;
    } catch (e) {
      error.value = (e as Error).message;
      return Promise.reject(e);
    }
  };

  return {
    addresses,
    addressParams,
    error,
    fetchAddresses,
    saveAddress,
    resetAddressParams,
  };
}
