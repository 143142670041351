<template>
  <div
    class="text-primary absolute top-0 left-0 right-0 bottom-0 z-50 w-full h-full min-w-full min-h-full"
  >
    <svg
      width="50"
      height="50"
      stroke-dasharray="0, 112"
      stroke-dashoffset="0"
      xmlns="http://www.w3.org/2000/svg"
      class="h-[50px] w-[50px] absolute top-1/2 left-1/2 -ml-[25px] -mt-[25px] ln-loader"
    >
      <circle
        cx="25"
        cy="25"
        r="18"
        stroke-width="7"
        stroke="currentColor"
        fill="transparent"
      ></circle>
    </svg>
    <span class="sr-only">Loading...</span>
  </div>
</template>

<script lang="ts">
export default {
  name: "CheckoutLoader",
};
</script>
