import type { AxiosResponse } from "axios";
import { computed, ref } from "vue";
import { tryCoupon } from "@/scripts/application/api";
import { useRoute } from "vue-router";
import { useOrder } from "@/scripts/application/composables";
import axios from "axios";

// const coupon = ref<ICoupon | null>(null);
export default function useCoupon() {
  const error = ref<string>();
  const route = useRoute();
  const { course_id, order_uid } = route.params;
  const { order } = useOrder();
  const coupon = computed({
    get() {
      return order.value?.coupon?.code;
    },
    set(value: string | undefined) {
      if (!order.value) return;
      order.value.coupon_code = value || "";
    },
  });

  async function checkCoupon(couponCode: string | undefined) {
    if (!couponCode) {
      error.value = undefined;
      coupon.value = undefined;
      return null;
    }
    try {
      const couponResponse = await tryCoupon(
        course_id as string,
        order_uid as string,
        couponCode
      );
      coupon.value = couponResponse.code;
      error.value = undefined;
      return coupon.value;
    } catch (err) {
      if (axios.isAxiosError(err)) {
        const errorResponse = err.response as
          | AxiosResponse<{ error: string }>
          | undefined;
        error.value = errorResponse?.data?.error || (err as Error).message;
      } else {
        error.value = (err as Error).message;
      }
      coupon.value = undefined;
      return Promise.reject(err);
    }
  }

  return {
    coupon,
    checkCoupon,
    error,
  };
}
