<template>
  <div class="pt-5 pb-5">
    <ul
      class="ln-radioTabs flex bg-gray-200 bg-opacity-70 rounded-lg p-2"
      v-if="addressType !== 'basic'"
    >
      <li class="flex-1">
        <label class="flex-1 block h-full relative">
          <input
            type="radio"
            v-model="invoice_type"
            name="address_type"
            value="individual"
            class="absolute opacity-0 pointer-events-none"
          />
          <span
            class="block rounded-md lg:text-lg min-h-11 p-2 sm:p-4 cursor-pointer hover:text-gray-700 text-gray-500 transition-all duration-200 h-full text-center"
          >
            <span class="flex flex-col items-center justify-end h-full">
              <span>{{ t("individual") }}</span>
            </span>
          </span>
        </label>
      </li>
      <li class="flex-1">
        <label class="flex-1 block h-full relative">
          <input
            type="radio"
            v-model="invoice_type"
            name="address_type"
            value="company"
            class="absolute opacity-0 pointer-events-none"
          />
          <span
            class="block rounded-md lg:text-lg min-h-11 p-2 sm:p-4 cursor-pointer hover:text-gray-700 text-gray-500 transition-all duration-200 h-full text-center"
          >
            <span class="flex flex-col items-center justify-end h-full">
              <span>{{ t("company") }}</span>
            </span>
          </span>
        </label>
      </li>
    </ul>

    <div class="pt-2 pb-4">
      <label
        class="block w-full py-5"
        v-if="availableAddresses && availableAddresses.length > 0"
      >
        <span class="text-custom-text pb-1 text-sm font-semibold"> </span>
        <select
          class="block py-2 pl-3 border border-gray-300 rounded-md cursor-pointer placeholder-gray-500 transition outline-none w-full shadow-sm min-h-12 text-custom-text bg-gray-200 bg-opacity-70 focus:bg-white focus:border-gray-500"
          v-model="activeAddress"
        >
          <option
            v-for="address in availableAddresses"
            :value="address"
            :key="address.id"
          >
            {{ address.address1 }}, {{ address.city }},
            {{ address.country }}
          </option>
          <option :value="null">
            {{ t("add_new_address") }}
          </option>
        </select>
      </label>
    </div>
    <template v-if="invoiceType === 'company'">
      <div class="pt-2 pb-4">
        <checkout-company-address
          v-if="props.addressID === null"
          @company:selected="transformCompanyData"
        />
        <template v-else>
          <label for="company-name" class="block w-full">
            <span
              class="flex items-end justify-between text-custom-text pb-1 text-sm font-semibold"
            >
              {{ t("company_name") }}
            </span>
          </label>
          <span class="text-lg font-semibold">{{ addressParams.company }}</span>
        </template>
      </div>

      <div class="pt-2 pb-4">
        <input-with-label
          name="organization_id"
          :optional="true"
          v-model.lazy.number="addressParams.organization_id"
        >
          {{ t("organization_id") }}
        </input-with-label>
        <p class="flex items-center text-custom-grey-2 pt-1.5 text-sm">
          <svg class="svg-icon svg-attention mr-1.5">
            <use xlink:href="#attention" width="100%" height="100%"></use>
          </svg>
          <span>
            {{ t("organization_id_info") }}
          </span>
        </p>
      </div>
    </template>
    <div class="pt-2 pb-4">
      <input-with-label
        name="address1"
        required
        v-model.lazy.trim="addressParams.address1"
      >
        {{ t("address") }}
      </input-with-label>
    </div>
    <div
      class="pt-2 pb-4 lg:flex justify-between space-y-4 lg:space-y-0 lg:space-x-3"
    >
      <input-with-label
        required
        name="postal_code"
        v-model.lazy.trim="addressParams.postal_code"
      >
        {{ t("postal_code") }}
      </input-with-label>
      <input-with-label
        required
        name="city"
        v-model.lazy.trim="addressParams.city"
      >
        {{ t("city") }}
      </input-with-label>
      <label class="block w-full">
        <span class="text-custom-text pb-1 text-sm font-semibold">
          {{ t("country") }}
        </span>
        <select
          name="country"
          v-model.lazy="addressParams.country"
          class="block py-2 pl-3 border border-gray-300 rounded-md cursor-pointer placeholder-gray-500 transition outline-none w-full shadow-sm min-h-12 bg-gray-200 bg-opacity-70 focus:bg-white focus:border-gray-500"
        >
          <option
            v-for="countryItem in countries"
            :key="countryItem.code"
            :value="countryItem.code"
          >
            {{ countryItem.name }}
          </option>
        </select>
      </label>
    </div>

    <div class="pt-2 pb-4 border-t" v-if="addressType === 'company'">
      <div
        class="md:flex justify-between space-y-4 md:space-y-0 md:space-x-3 pt-2 pb-2"
      >
        <input-with-label
          name="contact_person"
          required
          v-model.lazy.trim="addressParams.contact_person"
        >
          {{ t("contact_person") }}
        </input-with-label>
        <input-with-label
          name="billing_ref"
          :optional="true"
          v-model.lazy.trim="addressParams.billing_ref"
        >
          {{ t("billing_ref") }}
        </input-with-label>
      </div>
      <div class="pt-2 pb-4">
        <input-with-label
          name="billing_email"
          required
          :rules="{ email: true }"
          v-model.lazy.trim="addressParams.billing_email"
        >
          {{ t("billing_email") }}
        </input-with-label>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import { computed, onMounted, watch, watchEffect } from "vue";
import { useI18n } from "vue-i18n";
import type { IAddress, IBrregCompanyInfo } from "@/scripts/application/types";
import CheckoutCompanyAddress from "@/scripts/application/components/CheckoutCompanyAddress.vue";
import useAddresses from "../composables/useAddresses";
import useCountries from "../composables/useCountries";
import InputWithLabel from "@/scripts/application/components/InputWithLabel.vue";

const emit = defineEmits<{
  (event: "update:address", data: Partial<IAddress> | null): void;
  (event: "update:addressID", data: number | null): void;
  (event: "update:invoiceType", data: "individual" | "company"): void;
}>();
const props = defineProps<{
  addressID: number | null;
  invoiceType: "company" | "individual";
  addressType: "basic" | "individual" | "company";
}>();
const { t } = useI18n();
const { addresses, addressParams, fetchAddresses, resetAddressParams } =
  useAddresses();
const { countries, fetchCountries } = useCountries();

onMounted(() => {
  fetchAddresses();
  fetchCountries();
});

const availableAddresses = computed(() => {
  if (!addresses.value) return [];
  if (props.addressType === "company") {
    return addresses.value.filter((a) => a.billing_email);
  } else {
    return addresses.value.filter((a) => !a.billing_email);
  }
});

const activeAddress = computed<IAddress | null>({
  get(): IAddress | null {
    if (addresses.value === undefined) return null;
    return addresses.value.find((a) => a.id === props.addressID) || null;
  },
  set(value: IAddress | null) {
    emit("update:addressID", value?.id ?? null);
  },
});
const invoice_type = computed<"company" | "individual">({
  get(): "company" | "individual" {
    return props.invoiceType;
  },
  set(value: "individual" | "company") {
    emit("update:invoiceType", value);
  },
});

watch(
  () => addressParams.value,
  (value: IAddress) => {
    emit("update:address", value);
  },
  { deep: true }
);
watchEffect(() => {
  if (activeAddress.value) {
    addressParams.value = {
      ...activeAddress.value,
    };
  } else {
    resetAddressParams();
  }
});

function transformCompanyData(company: IBrregCompanyInfo) {
  const companyData = {
    company: company.name,
    organization_id: parseInt(company.organizationId),
    address1: company.address,
    postal_code: company.postalCode,
    city: company.city,
    country: "NO",
  };
  addressParams.value = {
    ...addressParams.value,
    ...companyData,
  };
  emit("update:address", addressParams.value);
}
</script>

<i18n lang="yaml">
en:
  first_name: "First name"
  last_name: "Last name"
  email: "Email"
  mobile_number: Mobile number
  address: Street address
  postal_code: Postal code
  city: City
  country: Country
  company_name: Company name
  contact_person: Contact person
  organization_id: Organization no.
  billing_email: Billing email address
  billing_ref: PO / Reference
  optional: Optional
  individual: Individual
  company: Company
  add_new_address: Add new address
  organization_id_info: Enter a Norwegian business registration number, 9 digits
  no_results: No results found in the public directory
no:
  first_name: "Fornavn"
  last_name: "Etternavn"
  email: "E-post"
  mobile_number: Mobilnummer
  address: Gateadresse
  postal_code: Postnummer
  city: By
  country: Land
  company_name: Bedriftsnavn
  contact_person: Kontaktperson
  organization_id: Organisasjonsnummer
  billing_email: Faktura e-postadresse
  billing_ref: PO / Referanse
  optional: Valgfritt
  individual: Privatperson
  company: Bedrift
  add_new_address: Legg til ny adresse
  organization_id_info: Oppgi et norsk organisasjonsnummer, 9 siffer
  no_results: Fant ingen firma med dette navnet i offentlige registre
nb:
  first_name: "Fornavn"
  last_name: "Etternavn"
  email: "E-post"
  mobile_number: Mobilnummer
  address: Gateadresse
  postal_code: Postnummer
  city: By
  country: Land
  company_name: Bedriftsnavn
  contact_person: Kontaktperson
  organization_id: Organisasjonsnummer
  billing_email: Faktura e-postadresse
  billing_ref: PO / Referanse
  optional: Valgfritt
  individual: Privatperson
  company: Bedrift
  add_new_address: Legg til ny adresse
  organization_id_info: Oppgi et norsk organisasjonsnummer, 9 siffer
  no_results: Fant ingen firma med dette navnet i offentlige registre
uk:
  first_name: "Ім'я"
  last_name: "Прізвище"
  email: "Електронна пошта"
  mobile_number: Мобільний номер
  address: Вулиця
  postal_code: Поштовий індекс
  city: Місто
  country: Країна
  company_name: Назва компанії
  contact_person: Контактна особа
  organization_id: Ідентифікатор організації
  billing_email: Електронна адреса для рахунків
  billing_ref: ПО / посилання
  optional: Необов'язково
  individual: Фізична особа
  company: Компанія
  add_new_address: Додати нову адресу
  organization_id_info: Введіть номер бізнес-реєстрації в Норвегії, 9 цифр
  no_results: Не знайдено результатів у публічному каталозі
es:
  first_name: "Nombre"
  last_name: "Apellido"
  email: "Correo electrónico"
  mobile_number: Número de teléfono móvil
  address: Dirección
  postal_code: Código postal
  city: Ciudad
  country: País
  company_name: Nombre de la empresa
  contact_person: Persona de contacto
  organization_id: Identificación de la organización
  billing_email: Dirección de correo electrónico de facturación
  billing_ref: PO / Referencia
  optional: Opcional
  individual: Persona física
  company: Empresa
  add_new_address: Agregar nueva dirección
  organization_id_info: Ingrese un número de registro de negocios noruego, 9 dígitos
  no_results: No se encontraron resultados en el directorio público
pl:
  first_name: "Imię"
  last_name: "Nazwisko"
  email: "E-mail"
  mobile_number: Numer telefonu komórkowego
  address: Adres
  postal_code: Kod pocztowy
  city: Miasto
  country: Kraj
  company_name: Nazwa firmy
  contact_person: Osoba kontaktowa
  organization_id: Identyfikator organizacji
  billing_email: Adres e-mail do faktur
  billing_ref: PO / Referencja
  optional: Opcjonalnie
  individual: Osoba fizyczna
  company: Firma
  add_new_address: Dodaj nowy adres
  organization_id_info: Wprowadź norweski numer rejestracyjny firmy, 9 cyfr
  no_results: Nie znaleziono wyników w katalogu publicznym
</i18n>
